<template>
  <v-container fluid class="pb-8" id="page">
    <ProcessingDetails
      v-if="currentSection === 'start' && getOrder.orderType"
      :items="getOrder.film"
      :order="getOrder"
      @onChange="onChangeProcessingDetails($event)"
    />
    <ScanningDetails
      v-if="
        (currentSection === 'scanning' || currentSection === 'finish') &&
        getOrder.orderType
      "
      :items="getOrder.film"
      :gateApertures="gateApertures"
      :frameRates="frameRates"
      :editorialMedia="editorialMedia"
      :editorialMediaResolutions="editorialMediaResolution"
      :editorialMediaCodecs="editorialMediaCodec"
      :editorialMediaColors="editorialMediaColor"
      :uncompressedMediaCodecs="uncompressedMediaCodec"
      :order="getOrder"
      @onChange="onChangeScanningDetails($event)"
      @onChangeSection="changeSection($event)"
    />
    <v-row
      v-if="
        currentSection === 'start' &&
        (getOrder.orderType === 'Processing & Scanning' ||
          getOrder.orderType === 'Processing Only')
      "
      class="pt-4"
      align="center"
      justify="center"
    >
      <v-col cols="6" class="d-flex justify-start">
        <v-row>
          <v-col cols="6" class="d-flex justify-start">
            <v-btn right plain outlined @click="startOver"> Start Over </v-btn>
          </v-col>
          <v-col cols="6" class="d-flex justify-end">
            <v-btn class="mr-4" left plain outlined @click="nextStep(2)">
              Back
            </v-btn>
            <v-btn
              v-if="getOrder.orderType === 'Processing & Scanning'"
              right
              plain
              outlined
              @click="currentSection = 'scanning'"
            >
              Next
            </v-btn>
            <v-btn v-else right plain outlined @click="nextStep(4)">
              Next
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      v-if="
        currentSection === 'start' && getOrder.orderType === 'Scanning Only'
      "
      class="pt-4"
      align="center"
      justify="center"
    >
      <v-col cols="6" class="d-flex justify-end">
        <v-btn class="mr-4" left plain outlined @click="nextStep(2)">
          Back
        </v-btn>
        <v-btn right plain outlined @click="nextStep(4)"> Next </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-if="currentSection === 'scanning'"
      class="pt-4"
      align="center"
      justify="center"
    >
      <v-col cols="6" class="d-flex justify-start">
        <v-row>
          <v-col cols="6" class="d-flex justify-start">
            <v-btn right plain outlined @click="startOver"> Start Over </v-btn>
          </v-col>
          <v-col cols="6" class="d-flex justify-end">
            <v-btn class="mr-4" left plain outlined @click="nextStep(2)">
              Back
            </v-btn>
            <v-btn
              right
              plain
              outlined
              @click="nextStep(4)"
              :disabled="isDisabledScanning"
            >
              Next
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <!-- <v-col cols="6" class="d-flex justify-end">
        <v-btn class="mr-4" left plain outlined @click="nextStep(2)">
          Back
        </v-btn>
        <v-btn right plain outlined @click="nextStep(4)"> Next </v-btn>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import ProcessingDetails from '@/components/ProcessingDetails'
import ScanningDetails from '@/components/ScanningDetails'

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'details',
  title: 'Details',
  components: {
    ProcessingDetails,
    ScanningDetails
  },
  props: {
    project: Object
  },
  data: function () {
    return {
      currentSection: 'start',
      order: null,
      gateApertures: [
        { text: 'Full aperture', value: 'Full aperture' },
        { text: 'Max aperture', value: 'Max aperture' },
        { text: 'Overscan', value: 'Overscan' }
      ],
      frameRates: [
        { text: '24fps', value: '24fps' },
        { text: '25fps', value: '25fps' }
      ],
      editorialMedia: [
        { text: 'DPX only', value: 'DPX only' },
        { text: 'DPX & ProRes', value: 'DPX & ProRes' },
        { text: 'ProRes only', value: 'ProRes only' }
      ],
      editorialMediaResolution: [
        { text: 'HD', value: 'HD' },
        { text: '2K', value: '2K' },
        { text: '4K ', value: '4K' }
      ],
      editorialMediaCodec: [
        { text: 'ProRes422 / HD', value: 'ProRes422 / HD' },
        { text: 'ProRes422 / HQ', value: 'ProRes422 / HQ' },
        { text: 'ProRes4444 / HQ', value: 'ProRes4444 / HQ' },
        { text: 'ProRes4444 / XQ', value: 'ProRes4444 / XQ' },
        { text: 'Avid DNx36', value: 'Avid DNx36' },
        { text: 'Avid DNx115', value: 'Avid DNx115' }
      ],
      editorialMediaColor: [
        { text: 'Log (Film log)', value: 'Log (Film log)' },
        { text: 'Linear (Rec709)', value: 'Linear (Rec709)' },
        { text: 'Colorist needed?', value: 'Colorist needed?' }
      ],
      uncompressedMediaCodec: [
        { text: 'DPX', value: 'DPX' },
        { text: 'EXR', value: 'EXR' }
      ]
    }
  },
  computed: {
    ...mapState('snackbar', {
      snackbars: (state) => state.snackbars
    }),
    ...mapGetters({
      getOrder: 'order/getOrder'
    }),
    isDisabledScanning () {
      if (
        this.getOrder.scanning &&
        this.order.scanning.frameRate &&
        this.order.scanning.resolution &&
        this.order.scanning.gateAperture
      ) {
        if (this.order.scanning.editorialMedia && this.order.scanning.editorialMedia === 'No') {
          return false
        } else if (
          this.order.scanning.editorialMedia &&
          (this.order.scanning.editorialMedia === 'Uncompressed & Editorial' ||
            this.order.scanning.editorialMedia === 'Uncompressed only')
        ) {
          if (this.order.scanning.uncompressedMediaCodec) return false
          else return true
        } else if (
          this.order.scanning.editorialMedia &&
          (this.order.scanning.editorialMedia === 'Uncompressed & Editorial' ||
            this.order.scanning.editorialMedia === 'Editorial only')
        ) {
          if (
            this.order.scanning.uncompressedMediaCodec &&
            this.order.scanning.uncompressedMediaColor &&
            this.order.scanning.uncompressedMediaResolution
          ) {
            return false
          } else return true
        } else return true
      } else {
        return true
      }
    }
  },
  async created () {
    this.checkParams()
  },
  methods: {
    startOver () {
      this.$emit('startOver')
    },
    onChangeProcessingDetails (event) {
      this.$emit('onChangeProcessingDetails', {
        order: event.project
      })
    },
    onChangeScanningDetails (event) {
      this.$emit('onChangeProcessingDetails', {
        order: event.project
      })
    },
    changeSection (event) {
      this.currentSection = event.section
    },
    nextStep (n) {
      this.$emit('nextStep', {
        n: n
      })
    },
    async checkParams () {
      this.order = this.getOrder
    }
  }
}
</script>
