<template>
  <div>
    <v-row class="pt-12" align="center" justify="center">
      <v-col cols="6">
        <h4 class="text-center text-h4">Processing Details</h4>
      </v-col>
    </v-row>
    <div class="pb-8 x" v-if="currentSection === 'start'">
      <v-row class="pt-12 pb-8" align="center" justify="center">
        <v-col cols="6" class="d-flex">
          <v-expansion-panels v-model="panel" accordion hover focusable>
            <v-expansion-panel v-for="(item, i) in film" :key="i">
              <v-expansion-panel-header
                v-if="item.processing.isNeeded"
                disable-icon-rotate
                @click="onOpen(item)"
                >{{ item.summary }}
                <template v-slot:actions>
                  <v-btn small plain color="success">Add Instructions</v-btn>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row
                  v-if="item.uuid"
                  class="pt-6"
                  align="center"
                  justify="center"
                >
                  <v-col cols="12" class="d-flex justify-center align-center">
                    <p class="font-weight-bold">
                      Service Include Leader &amp; Splice, Ultrasonic Cleaning
                    </p>
                  </v-col>
                </v-row>
                <v-row
                  v-if="item.uuid"
                  class="pt-0"
                  align="center"
                  justify="center"
                >
                  <v-col
                    cols="12"
                    class="pt-0 d-flex justify-center align-center"
                  >
                    <div class="pr-4">Processing Instructions:</div>
                    <v-radio-group
                      v-model="currentItem.processingInstructions"
                      row
                    >
                      <v-radio label="Normal" value="Normal"></v-radio>
                      <v-radio label="Special" value="Special"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row
                  v-if="
                    item.uuid &&
                    currentItem.processingInstructions === 'Special'
                  "
                  class="pt-1"
                  align="center"
                  justify="center"
                >
                  <v-col
                    cols="12"
                    class="pb-1 d-flex justify-center align-center"
                  >
                    <p>Additional Processing Instructions</p>
                  </v-col>
                </v-row>
                <v-row
                  v-if="
                    item.uuid &&
                    currentItem.processingInstructions === 'Special'
                  "
                  class="pt-0"
                  align="center"
                  justify="center"
                >
                  <v-col cols="12" class="pt-0 d-flex">
                    <v-textarea
                      outlined
                      v-model="currentItem.additionalProcessingInstructions"
                      placeholder="None"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row
                  v-if="item.uuid"
                  class="pt-4 pb-4"
                  align="center"
                  justify="center"
                >
                  <v-btn text color="success" @click="onSave(item)">save</v-btn>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { v4 as uuid } from 'uuid'
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  name: 'ProcessingDetails',
  props: {
    items: Array,
    order: Object
  },
  data: function () {
    return {
      currentSection: 'start',
      panel: null,
      project: { ...this.order },
      currentItem: {
        uuid: uuid(),
        processingInstructions: 'Normal',
        additionalProcessingInstructions: 'None'
      }
    }
  },
  computed: {
    ...mapState('snackbar', {
      snackbars: (state) => state.snackbars
    }),
    ...mapGetters({
      getOrder: 'order/getOrder',
      film: 'order/getFilm'
    }),
    processingInstructionsErrors () {
      const errors = []

      if (!this.$v.currentItem.processingInstructions.$dirty) return errors

      if (!this.$v.currentItem.processingInstructions.required) {
        errors.push('Film Format is required')
      }

      return errors
    }
  },
  mixins: [validationMixin],
  validations: {
    currentItem: {
      processingInstructions: {
        required
      }
    }
  },
  methods: {
    ...mapActions('order', ['setOrder', 'setFilmProcessing']),
    onSave (item) {
      const index = this.project.film.findIndex(
        (element) => element.uuid === item.uuid
      )

      if (index !== -1) {
        this.currentItem.uuid = uuid()

        this.setFilmProcessing({
          uuid: item.uuid,
          processing: { ...this.currentItem }
        })
        this.panel = null
        this.currentItem = {
          uuid: null,
          processingInstructions: 'Normal',
          additionalProcessingInstructions: 'None'
        }
      }
    },
    onOpen (item) {
      this.currentItem = { ...item.processing }
    },
    onAdd () {
      this.currentSection = 'start'
      this.$emit('onChangeSection', {
        section: 'add'
      })
    },
    onChange (e) {
      this.currentItem = { ...e }
    }
  }
}
</script>

<style scoped lang="scss">
.order-list > div > div {
  border-top: 1px solid #efefef;
}
.order-list > div:last-child > div {
  border-bottom: 1px solid #efefef;
}
</style>
