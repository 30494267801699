<template>
  <div>
    <v-row class="pt-12" align="center" justify="center">
      <v-col cols="6">
        <h4 class="text-center text-h4">Scanning Details</h4>
      </v-col>
    </v-row>
    <div class="py-8" v-if="currentSection === 'start'">
      <v-row class="pt-8 pb-8" align="center" justify="center">
        <v-col cols="6" class="d-flex justify-center align-center">
          <p class="font-weight-bold">
            Service include ultrasonic cleaning, 10-bit Log uncompressed (is
            standard)
          </p>
        </v-col>
      </v-row>
      <v-row
        v-if="currentItem.isNeeded"
        class="pt-0"
        align="center"
        justify="center"
      >
        <v-col cols="6" class="pt-0 d-flex justify-center align-center">
          <v-radio-group v-model="currentItem.resolution" row>
            <v-radio label="2K" value="2K"></v-radio>
            <v-radio label="4K" value="4K"></v-radio>
            <v-radio label="Unsure" value="Unsure"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row
        v-if="currentItem.isNeeded"
        class="pt-0"
        align="center"
        justify="center"
      >
        <v-col cols="6" class="d-flex">
          <v-select
            v-model="currentItem.gateAperture"
            :items="gateApertures"
            label="Gate Aperture"
            @change="onChangeGateAperture"
          ></v-select>
        </v-col>
      </v-row>
      <v-row
        v-if="currentItem.isNeeded"
        class="pt-4"
        align="center"
        justify="center"
      >
        <v-col cols="6" class="d-flex">
          <v-select
            v-model="currentItem.frameRate"
            :items="frameRates"
            label="Frame Rate"
            @change="onChangeFrameRate"
          ></v-select>
        </v-col>
      </v-row>
      <v-row
        v-if="currentItem.isNeeded"
        class="pt-1"
        align="center"
        justify="center"
      >
        <v-col cols="6" class="pb-1 d-flex justify-center align-center">
          <p>Additional Scanning Instructions</p>
        </v-col>
      </v-row>
      <v-row
        v-if="currentItem.isNeeded"
        class="pt-0"
        align="center"
        justify="center"
      >
        <v-col cols="6" class="pt-0 d-flex">
          <v-textarea
            outlined
            v-model="currentItem.additionalScanningInstructions"
            placeholder="None"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row
        v-if="currentItem.isNeeded"
        class="pt-1"
        align="center"
        justify="center"
      >
        <v-col cols="6" class="d-flex justify-center align-center">
          <p class="font-weight-bold">
            Do you need editorial media and/or uncompressed media?
          </p>
        </v-col>
      </v-row>

      <v-row
        v-if="currentItem.isNeeded"
        class="pt-1"
        align="center"
        justify="center"
      >
        <v-col cols="6" class="d-flex justify-center align-center">
          <v-radio-group
            v-model="currentItem.editorialMedia"
            @change="onChangeEditorial"
            row
          >
            <v-radio
              label="No"
              value="No"
            ></v-radio>
            <v-radio
              label="Uncompressed only"
              value="Uncompressed only"
            ></v-radio>
            <v-radio
              label="Uncompressed & Editorial"
              value="Uncompressed & Editorial"
            ></v-radio>
            <v-radio label="Editorial only" value="Editorial only"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
       <v-row
        class="pt-4"
        align="center"
        justify="center"
      >
        <v-col cols="6" class="d-flex justify-center align-center">
          <v-divider />
        </v-col>
      </v-row>
      <v-row
        v-if="
          currentItem.editorialMedia === 'Uncompressed & Editorial' ||
          currentItem.editorialMedia === 'Uncompressed only'
        "
        class="pt-4"
        align="center"
        justify="center"
      >
        <v-col cols="6" class="d-flex justify-center align-center">
          <p class="font-weight-bold">Uncompressed Specifications</p>
        </v-col>
      </v-row>

      <v-row
        v-if="
          currentItem.editorialMedia === 'Uncompressed & Editorial' ||
          currentItem.editorialMedia === 'Uncompressed only'
        "
        class="pt-0"
        align="center"
        justify="center"
      >
        <v-col cols="6" class="d-flex">
          <v-select
            v-model="currentItem.uncompressedMediaCodec"
            :items="uncompressedMediaCodecs"
            label="Uncompressed CODEC"
            @change="onChangeUncompressedMediaCodec"
            :error-messages="uncompressedMediaCodecErrors"
          ></v-select>
        </v-col>
      </v-row>

       <v-row
        v-if="
          currentItem.editorialMedia === 'Uncompressed & Editorial'
        "
        class="pt-8"
        align="center"
        justify="center"
      >
        <v-col cols="6" class="d-flex justify-center align-center">
          <v-divider />
        </v-col>
      </v-row>

      <v-row
        v-if="
          currentItem.editorialMedia === 'Uncompressed & Editorial' ||
          currentItem.editorialMedia === 'Editorial only'
        "
        class="pt-4"
        align="center"
        justify="center"
      >
        <v-col cols="6" class="d-flex justify-center align-center">
          <p class="font-weight-bold">Editorial Specifications</p>
        </v-col>
      </v-row>

      <v-row
        v-if="
          currentItem.editorialMedia === 'Uncompressed & Editorial' ||
          currentItem.editorialMedia === 'Editorial only'
        "
        class="pt-0"
        align="center"
        justify="center"
      >
        <v-col cols="6" class="d-flex">
          <v-select
            v-model="currentItem.editorialMediaCodec"
            :items="editorialMediaCodecs"
            label="Editorial CODEC"
            @change="onChangeMediaCodec"
            :error-messages="editorialMediaCodecErrors"
          ></v-select>
        </v-col>
      </v-row>

      <v-row
        v-if="
          currentItem.editorialMedia === 'Uncompressed & Editorial' ||
          currentItem.editorialMedia === 'Editorial only'
        "
        class="pt-4"
        align="center"
        justify="center"
      >
        <v-col cols="6" class="d-flex">
          <v-select
            v-model="currentItem.editorialMediaResolution"
            :items="editorialMediaResolutions"
            label="Editorial Resolution"
            @change="onChangeMediaResolution"
            :error-messages="editorialMediaResolutionErrors"
            :hint="
              currentItem.editorialMediaResolution === '4K' &&
              currentItem.resolution === '2K'
                ? 'No upres for 2K'
                : ''
            "
            persistent-hint
          ></v-select>
        </v-col>
      </v-row>

      <v-row
        v-if="
          currentItem.editorialMedia === 'Uncompressed & Editorial' ||
          currentItem.editorialMedia === 'Editorial only'
        "
        class="pt-4"
        align="center"
        justify="center"
      >
        <v-col cols="6" class="d-flex">
          <v-select
            v-model="currentItem.editorialMediaColor"
            :items="editorialMediaColors"
            label="Editorial Color"
            @change="onChangeMediaColor"
            :error-messages="editorialMediaColorErrors"
          ></v-select>
        </v-col>
      </v-row>

      <v-row class="pt-4 pb-4" align="center" justify="center">
        <v-btn text color="success" @click="onSave()">save</v-btn>
      </v-row>
    </div>

    <div
      class="pb-12"
      v-if="currentSection === 'add' || currentSection === 'finish'"
    >
      <v-row class="pt-8 pb-4" align="center" justify="center">
        <v-btn text large color="success" @click="onAdd"
          ><v-icon left small>mdi-plus</v-icon>Add Another Film Format</v-btn
        >
      </v-row>
      <div class="order-list">
        <v-row
          v-for="(item, index) in project.scanning"
          v-bind:key="index"
          class="pb-0 order-list"
          align="center"
          justify="center"
        >
          <v-col
            cols="6"
            class="pt-0 pb-0 d-flex align-center justify-space-between"
          >
            <span class="font-weight-bold">{{ item.summary }} </span>

            <span>
              <v-btn class="ml-4" icon color="warning"
                ><v-icon small>mdi-delete</v-icon></v-btn
              >
              <v-btn icon color="success"
                ><v-icon small>mdi-pencil</v-icon></v-btn
              >
            </span>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  name: 'ScanningDetails',
  props: {
    items: Array,
    order: Object,
    gateApertures: Array,
    frameRates: Array,
    editorialMedia: Array,
    editorialMediaResolutions: Array,
    editorialMediaCodecs: Array,
    uncompressedMediaCodecs: Array,
    editorialMediaColors: Array
  },
  data: function () {
    return {
      panel: null,
      currentSection: 'start',
      needEditorial: false,
      project: { ...this.order },
      currentItem: {
        isNeeded:
          this.order.orderType === 'Scanning Only' ||
          this.order.orderType === 'Processing & Scanning',
        resolution: this.order.scanning.resolution || '2K',
        gateAperture: this.order.scanning.gateAperture || 'Full aperture',
        frameRate: this.order.scanning.frameRate || '24fps',
        additionalScanningInstructions:
          this.order.scanning.additionalScanningInstructions || null,
        editorialMedia: this.order.scanning.editorialMedia || 'No',
        editorialMediaResolution:
          this.order.scanning.editorialMediaResolution || null,
        editorialMediaCodec: this.order.scanning.editorialMediaCodec || null,
        editorialMediaColor: this.order.scanning.editorialMediaColor || null,
        uncompressedMediaCodec:
          this.order.scanning.uncompressedMediaCodec || null
      }
    }
  },
  computed: {
    ...mapState('snackbar', {
      snackbars: (state) => state.snackbars
    }),
    ...mapGetters({
      getOrder: 'order/getOrder',
      film: 'order/getFilm'
    }),
    editorialMediaResolutionErrors () {
      const errors = []
      if (
        this.currentItem.editorialMedia === 'Uncompressed & Editorial' ||
        this.currentItem.editorialMedia === 'Editorial only'
      ) {
        if (!this.$v.currentItem.editorialMediaResolution.$dirty) return errors

        if (!this.$v.currentItem.editorialMediaResolution.required) {
          errors.push('Media Resolution is required')
        }
        console.log(errors)
      }
      return errors
    },
    editorialMediaCodecErrors () {
      const errors = []
      if (
        this.currentItem.editorialMedia === 'Uncompressed & Editorial' ||
        this.currentItem.editorialMedia === 'Editorial only'
      ) {
        if (!this.$v.currentItem.editorialMediaCodec.$dirty) return errors

        if (!this.$v.currentItem.editorialMediaCodec.required) {
          errors.push('Media CODEC is required')
        }
        console.log(errors)
      }
      return errors
    },
    editorialMediaColorErrors () {
      const errors = []
      if (
        this.currentItem.editorialMedia === 'Uncompressed & Editorial' ||
        this.currentItem.editorialMedia === 'Editorial only'
      ) {
        if (!this.$v.currentItem.editorialMediaColor.$dirty) return errors

        if (!this.$v.currentItem.editorialMediaColor.required) {
          errors.push('Media Color is required')
        }
        console.log(errors)
      }
      return errors
    },
    uncompressedMediaCodecErrors () {
      const errors = []
      if (
        this.currentItem.editorialMedia === 'Uncompressed & Editorial' ||
        this.currentItem.editorialMedia === 'Uncompressed only'
      ) {
        if (!this.$v.currentItem.uncompressedMediaCodec.$dirty) return errors

        if (!this.$v.currentItem.uncompressedMediaCodec.required) {
          errors.push('Media CODEC is required')
        }
        console.log(errors)
      }
      return errors
    }
  },
  mixins: [validationMixin],
  validations: {
    currentItem: {
      editorialMediaResolution: {
        required
      },
      editorialMediaCodec: {
        required
      },
      editorialMediaColor: {
        required
      },
      uncompressedMediaCodec: {
        required
      }
    }
  },
  methods: {
    ...mapActions('order', ['setOrder', 'removeScanning', 'setScanning']),
    onSave () {
      this.setScanning({
        scanning: { ...this.currentItem }
      })
      // this.panel = null
      // this.currentItem = {
      //   isNeeded:
      //     this.order.orderType === 'Scanning Only' ||
      //     this.order.orderType === 'Processing & Scanning',
      //   resolution: '2K',
      //   gateAperture: 'Full aperture',
      //   frameRate: '24fps',
      //   additionalScanningInstructions: null,
      //   editorialMedia: null,
      //   editorialMediaResolution: null,
      //   editorialMediaCodec: null,
      //   editorialMediaColor: null
      // }
      // this.panel = null
      // // this.$emit('onChange', {
      // //   project: this.project
      // // })
      // this.currentSection = 'start'
      // this.$emit('onChangeSection', {
      //   section: 'scanning'
      // })
    },
    onAdd () {
      this.currentSection = 'start'
      this.$emit('onChangeSection', {
        section: 'scanning'
      })
    },
    onOpen (item) {
      this.currentItem = { ...item.scanning }
    },
    onChange (e) {
      this.currentItem = { ...e }
    },
    onChangeEditorial (e) {
      this.currentItem.editorialMedia = e
    },
    onChangeGateAperture (e) {
      this.currentItem.gateAperture = e
    },
    onChangeFrameRate (e) {
      this.currentItem.frameRate = e
    },
    onChangeMediaResolution (e) {
      this.currentItem.editorialMediaResolution = e
    },
    onChangeMediaCodec (e) {
      this.currentItem.editorialMediaCodec = e
    },
    onChangeUncompressedMediaCodec (e) {
      this.currentItem.uncompressedMediaCodec = e
    },
    onChangeMediaColor (e) {
      this.currentItem.editorialMediaColor = e
    }
  }
}
</script>

<style scoped lang="scss">
.order-list > div > div {
  border-top: 1px solid #efefef;
}
.order-list > div:last-child > div {
  border-bottom: 1px solid #efefef;
}
</style>
