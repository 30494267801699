var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pb-8",attrs:{"fluid":"","id":"page"}},[(_vm.currentSection === 'start' && _vm.getOrder.orderType)?_c('ProcessingDetails',{attrs:{"items":_vm.getOrder.film,"order":_vm.getOrder},on:{"onChange":function($event){return _vm.onChangeProcessingDetails($event)}}}):_vm._e(),(
      (_vm.currentSection === 'scanning' || _vm.currentSection === 'finish') &&
      _vm.getOrder.orderType
    )?_c('ScanningDetails',{attrs:{"items":_vm.getOrder.film,"gateApertures":_vm.gateApertures,"frameRates":_vm.frameRates,"editorialMedia":_vm.editorialMedia,"editorialMediaResolutions":_vm.editorialMediaResolution,"editorialMediaCodecs":_vm.editorialMediaCodec,"editorialMediaColors":_vm.editorialMediaColor,"uncompressedMediaCodecs":_vm.uncompressedMediaCodec,"order":_vm.getOrder},on:{"onChange":function($event){return _vm.onChangeScanningDetails($event)},"onChangeSection":function($event){return _vm.changeSection($event)}}}):_vm._e(),(
      _vm.currentSection === 'start' &&
      (_vm.getOrder.orderType === 'Processing & Scanning' ||
        _vm.getOrder.orderType === 'Processing Only')
    )?_c('v-row',{staticClass:"pt-4",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"d-flex justify-start",attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-start",attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"right":"","plain":"","outlined":""},on:{"click":_vm.startOver}},[_vm._v(" Start Over ")])],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"mr-4",attrs:{"left":"","plain":"","outlined":""},on:{"click":function($event){return _vm.nextStep(2)}}},[_vm._v(" Back ")]),(_vm.getOrder.orderType === 'Processing & Scanning')?_c('v-btn',{attrs:{"right":"","plain":"","outlined":""},on:{"click":function($event){_vm.currentSection = 'scanning'}}},[_vm._v(" Next ")]):_c('v-btn',{attrs:{"right":"","plain":"","outlined":""},on:{"click":function($event){return _vm.nextStep(4)}}},[_vm._v(" Next ")])],1)],1)],1)],1):_vm._e(),(
      _vm.currentSection === 'start' && _vm.getOrder.orderType === 'Scanning Only'
    )?_c('v-row',{staticClass:"pt-4",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"mr-4",attrs:{"left":"","plain":"","outlined":""},on:{"click":function($event){return _vm.nextStep(2)}}},[_vm._v(" Back ")]),_c('v-btn',{attrs:{"right":"","plain":"","outlined":""},on:{"click":function($event){return _vm.nextStep(4)}}},[_vm._v(" Next ")])],1)],1):_vm._e(),(_vm.currentSection === 'scanning')?_c('v-row',{staticClass:"pt-4",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"d-flex justify-start",attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-start",attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"right":"","plain":"","outlined":""},on:{"click":_vm.startOver}},[_vm._v(" Start Over ")])],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"mr-4",attrs:{"left":"","plain":"","outlined":""},on:{"click":function($event){return _vm.nextStep(2)}}},[_vm._v(" Back ")]),_c('v-btn',{attrs:{"right":"","plain":"","outlined":"","disabled":_vm.isDisabledScanning},on:{"click":function($event){return _vm.nextStep(4)}}},[_vm._v(" Next ")])],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }